import React, { useReducer, useEffect } from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ImFacebook, ImTwitter, ImYoutube, ImLinkedin } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import "./footer.scss";
import CustomIcon from "../../shared/customIcon/CustomIcon";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import axiosInstance from "../../../config/axios";
// footer component for mobile ,tablet ,desktop view
const Footer = () => {
  const getYear = () => {
    return new Date().getFullYear();
  };
  let activeStyle = {
    color: "#29b4b8",
  };
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [addressState, addressDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const loadAddress = async () => {
    addressDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`page-content/contact-us`);
      addressDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
    } catch (error) {
      addressDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`home-page-settings`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
    loadAddress();
  }, []);
  console.log(" ----------------- >>>> "+window.location.href.toLowerCase().indexOf("bony-fishes.html"))
  return (
    <>
      {(
        window.location.href.toLowerCase().indexOf("bony-fishes.html") <= 0
      && window.location.href.toLowerCase().indexOf("coelacanth.html") <= 0
      && window.location.href.toLowerCase().indexOf("family.html")  <= 0
      && window.location.href.toLowerCase().indexOf("living-fossils.html")  <= 0
      && window.location.href.toLowerCase().indexOf("octopus.html")  <= 0
      && window.location.href.toLowerCase().indexOf("plants.html")  <= 0
      && window.location.href.toLowerCase().indexOf("preparation.html")  <= 0
      && window.location.href.toLowerCase().indexOf("pycnodonts.html")  <= 0
      && window.location.href.toLowerCase().indexOf("rays.html")  <= 0
      && window.location.href.toLowerCase().indexOf("sharks.html")  <= 0
      && window.location.href.toLowerCase().indexOf("invertebrates.html")  <= 0
      //&& window.location.href.toLowerCase().indexOf("mot-gen-qr/index.html")  <= 0
        ) && <>
        <div className="subFooterSection center">
          <Row className="px-4 w-100  showOnMobile">
            <Col xs={12} md={4}>
              <Row>
                <Col>
                  <h1 className="visitHeading">Visit us</h1>
                  {addressState.data &&
                    addressState.data.addresses &&
                    addressState.data.addresses.map((address, index) => {
                      if (address.name === "address_line1")
                        return <h2 className="address mb-0">{address.value}</h2>;
                    })}
                  {addressState.data &&
                    addressState.data.addresses &&
                    addressState.data.addresses.map((address, index) => {
                      if (address.name === "address_line2")
                        return <h2 className="address">{address.value}</h2>;
                    })}

                  <h2 className="viewMap">
                    {" "}
                    <a href={detailsState.data.googleMapLink} target="_blank">
                      View on map
                    </a>
                  </h2>
                </Col>
                <Col xs={4} className="center">
                  {detailsState &&
                    detailsState.data &&
                    detailsState.data.googleMapLink && (
                      <a href={detailsState.data.googleMapLink} target="_blank">
                        <CustomIcon
                          icon="map"
                          size={100}
                          className="locationIcon"
                        />
                      </a>
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className=" w-100 pt-5 pb-3 uniPadding showOnTablet">
            <Col lg={12} xl={9}>
              <Row>
                <Col>
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText noCursor">Discover us</li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/our-history"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Our History
                      </NavLink>
                    </li>
                    <NavLink
                      className="footerLink"
                      to="/discover-us/fossil-sites"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      Fossil Sites
                    </NavLink>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/fossil-lab"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil Lab
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/the-shop"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        The Shop
                      </NavLink>
                    </li>
                    <li className="footerLink ">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/fossil-museum"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil Museum
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      {" "}
                      <NavLink
                        className="footerLink"
                        to="/discover-us/mim"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        MIM Museum
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      {" "}
                      <NavLink
                        className="footerLink"
                        to="/discover-us/international-relations"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        International Relations
                      </NavLink>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText noCursor">Fossils</li>

                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/fossils/memory-of-time-collection"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Memory of Time Collection
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      {" "}
                      <NavLink
                        className="footerLink"
                        to="/fossils/fossil's-science"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil's Science
                      </NavLink>
                    </li>
                    <li className="footerLink opacity-0">Fossil Lab</li>
                    <li className="footerLinkHeadText hover">
                      <NavLink className="footerLinkHeadText" to="/own-a-fossil">
                        Own a fossil
                      </NavLink>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText noCursor">Memories</li>

                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/memories/news-events"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        News & Events
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/memories/media-gallery"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Media Gallery
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/memories/press-releases"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Press Releases
                      </NavLink>
                    </li>
                    <li className="footerLink">Publications</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/contact-us"
                      >
                        Contact us
                      </NavLink>{" "}
                    </li>

                    <li className="footerLinkHeadText hover">
                      {" "}
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/how-to-reach-us"
                      >
                        How to reach us
                      </NavLink>
                    </li>
                    <li className="footerLinkHeadText hover">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/useful-links"
                      >
                        Useful Links
                      </NavLink>
                    </li>
                    <li className="footerLinkHeadText hover">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/testimonial"
                      >
                        Testimonials
                      </NavLink>
                    </li>
                    <li className="footerLinkHeadText hover">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/terms-and-conditions"
                      >
                        Terms & Conditions
                      </NavLink>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className=" w-100 uniPadding  showOnDesktop">
            <Col lg={9} xl={9} className="leftSection">
              <Row className="pt-3 pb-0">
                <Col className="">
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText noCursor">Discover us</li>
                    <li className="footerLink">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLink"
                        to="/discover-us/our-history"
                      >
                        Our History
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/fossil-sites"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil Sites
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/fossil-lab"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil Lab
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/the-shop"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        The Shop
                      </NavLink>
                    </li>
                    <li className="footerLink ">
                      <NavLink
                        className="footerLink"
                        to="/discover-us/fossil-museum"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil Museum
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      {" "}
                      <NavLink
                        className="footerLink"
                        to="/discover-us/mim"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        MIM Museum
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      {" "}
                      <NavLink
                        className="footerLink"
                        to="/discover-us/international-relations"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        International Relations
                      </NavLink>
                    </li>
                  </ul>
                </Col>
                <Col lg={4} xl={4} className="largeColumn me-4 py-0">
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText noCursor">Fossils</li>

                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/fossils/memory-of-time-collection"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Memory of Time Collection
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      {" "}
                      <NavLink
                        className="footerLink"
                        to="/fossils/fossil's-science"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Fossil's Science
                      </NavLink>
                    </li>
                    <li className="footerLink opacity-0">Fossil Lab</li>
                    <li className="footerLinkHeadText hover">
                      <NavLink className="footerLinkHeadText" to="/own-a-fossil">
                        Own a fossil
                      </NavLink>
                    </li>
                  </ul>
                </Col>
                <Col lg={2} xl={2} className=" p-0">
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText noCursor">Memories</li>

                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/memories/news-events"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        News & Events
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/memories/media-gallery"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Media Gallery
                      </NavLink>
                    </li>
                    <li className="footerLink">
                      <NavLink
                        className="footerLink"
                        to="/memories/press-releases"
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                      >
                        Press Releases
                      </NavLink>
                    </li>
                    <li className="footerLink">Publications</li>
                  </ul>
                </Col>
                <Col className=" ps-3 py-0">
                  <ul className="footerLinkHead">
                    <li className="footerLinkHeadText">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/contact-us"
                      >
                        Contact us
                      </NavLink>{" "}
                    </li>

                    <li className="footerLinkHeadText hover">
                      {" "}
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/how-to-reach-us"
                      >
                        How to reach us
                      </NavLink>
                    </li>
                    <li className="footerLinkHeadText hover">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/useful-links"
                      >
                        Useful Links
                      </NavLink>
                    </li>
                    <li className="footerLinkHeadText hover">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/testimonial"
                      >
                        Testimonials
                      </NavLink>
                    </li>
                    <li className="footerLinkHeadText hover">
                      <NavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        className="footerLinkHeadText"
                        to="/terms-and-conditions"
                      >
                        Terms & Conditions
                      </NavLink>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={3} xl={3} className="rightSection">
              <Row>
                <Col lg={7} xl={7}>
                  <h1 className="visitHeading">Visit us</h1>
                  {addressState.data &&
                    addressState.data.addresses &&
                    addressState.data.addresses.map((address, index) => {
                      if (address.name === "address_line1")
                        return <h2 className="address mb-0">{address.value}</h2>;
                    })}
                  {addressState.data &&
                    addressState.data.addresses &&
                    addressState.data.addresses.map((address, index) => {
                      if (address.name === "address_line2")
                        return <h2 className="address ">{address.value}</h2>;
                    })}
                  <h2 className="viewMap">
                    {" "}
                    <a href={detailsState.data.googleMapLink} target="_blank">
                      View on map
                    </a>
                  </h2>
                </Col>
                <Col lg={5} xl={5} className="center p-0">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip className="toolTip">
                        Click for a map preview
                      </Tooltip>
                    }
                  >
                    <span>
                      {detailsState &&
                        detailsState.data &&
                        detailsState.data.googleMapLink && (
                          <a
                            href={detailsState.data.googleMapLink}
                            target="_blank"
                          >
                            <CustomIcon
                              icon="map"
                              size={100}
                              className="locationIcon"
                            />
                          </a>
                        )}
                    </span>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>}





      <div className="footerSection center">
        <Row className="w-100 footerRow uniPadding">
          <Col xs={12} sm={6} md={6} lg={4} className="start">
            <div className="AheadLogoWrapper">
              <div className="AHEADLogoContainer">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip className="toolTip">Visit AHEAD</Tooltip>}
                >
                  <span>
                    <a
                      href="https://www.ahead.pro"
                      target="_blank"
                      className="aheadLogoAnimation"
                    ></a>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} className="copyRightColumn center">
            <p className="copyRight">
              &copy;{getYear()} Memory of time, All rights reserved.
            </p>
          </Col>

          <Col xs={12} sm={6} lg={4} xl={3} className="start hideOnMobileTablet socialMedia ps-xl-0">
            <div className="socialMedia center">
              <div className="center">
                {detailsState &&
                  detailsState.data &&
                  detailsState.data.socialChannels &&
                  detailsState.data.socialChannels.map((item, index) => {
                    if (item.name === "facebook" && item.value) {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip className="toolTip">
                              Visit our facebook page
                            </Tooltip>
                          }
                        >
                          <span>
                            <a
                              href={item.value}
                              target="_blank"
                              className="socialItem center"
                            >
                              <ImFacebook className="socialIcon" />
                            </a>
                          </span>
                        </OverlayTrigger>
                      );
                    }
                    if (item.name === "youtube" && item.value) {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip className="toolTip">
                              Visit our youtube channel
                            </Tooltip>
                          }
                        >
                          <span>
                            <a
                              href={item.value}
                              target="_blank"
                              className="socialItem center"
                            >
                              <ImYoutube className="socialIcon" />
                            </a>
                          </span>
                        </OverlayTrigger>
                      );
                    }
                    if (item.name === "twitter" && item.value) {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip className="toolTip">
                              Visit our twitter page
                            </Tooltip>
                          }
                        >
                          <span>
                            <a
                              href={item.value}
                              target="_blank"
                              className="socialItem center"
                            >
                              <ImTwitter className="socialIcon" />
                            </a>
                          </span>
                        </OverlayTrigger>
                      );
                    }
                    if (item.name === "instagram" && item.value) {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip className="toolTip">
                              Visit our instagram page
                            </Tooltip>
                          }
                        >
                          <span>
                            <a
                              href={item.value}
                              target="_blank"
                              className="socialItem center"
                            >
                              <AiFillInstagram className="socialIcon" />
                            </a>
                          </span>
                        </OverlayTrigger>
                      );
                    }
                    if (item.name === "linkedin" && item.value) {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip className="toolTip">
                              Visit our linkedin page
                            </Tooltip>
                          }
                        >
                          <span>
                            <a
                              href={item.value}
                              target="_blank"
                              className="socialItem center"
                            >
                              <ImLinkedin className="socialIcon" />
                            </a>
                          </span>
                        </OverlayTrigger>
                      );
                    }
                  })}
              </div>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip className="toolTip">
                    Click to tweet with this hashtag
                  </Tooltip>
                }
              >
                <span>
                  {detailsState &&
                    detailsState.data &&
                    detailsState.data.hashtag && (
                      <a
                        href={`http://twitter.com/intent/tweet?text=%23${detailsState.data.hashtag.slice(
                          1
                        )}`}
                        target="_blank"
                        className="hashtag mb-0 ps-1"
                      >
                        {detailsState.data.hashtag}{" "}
                      </a>
                    )}
                </span>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
