import React, { useReducer, useEffect } from "react";
import DiscoverUsSubPage from "../../layout/subPage/DiscoverUsSubpage";
import axiosInstance from "../../../config/axios";


import { Loading } from "../../shared/loading/Loading";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import TextSwiper from "../discoverUs/textSwiper/TextSwiper";
const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const PageContent = ({ pageSlug }) => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    await sleep(1000);
    try {
      const res = await axiosInstance.get(`page-content/${pageSlug}`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);

  useEffect(() => {


    if (detailsState.data.heading) {
      document.title = detailsState.data.heading;
    } else {
      document.title = "";
    }

  }, [detailsState.data]);

  return (
    <>
      {detailsState.isFetching === true ? (
        <Loading />
      ) : detailsState.hasError ? (
        <DiscoverUsSubPage error={true} />
      ) : (
        <DiscoverUsSubPage
          heading={detailsState.data.heading}
          leftContent={
            <TextSwiper
              layout="ourHistory"
              slide1={detailsState.data.slide1}
              slide2={detailsState.data.slide2}
              slide3={detailsState.data.slide3}
              slide4={detailsState.data.slide4}
              slide5={detailsState.data.slide5}
            />
          }
          title={
            detailsState.data.bannerCaption
              ? detailsState.data.bannerCaption
              : detailsState.data.heading
          }
          images={detailsState.data.banner}
          buttonText={detailsState.data.buttonText}
          buttonLink={detailsState.data.buttonLink}
          galleryCount={detailsState.data.galleryCount}
          slug={"page-gallery/" + pageSlug}
        />
      )}
    </>
  );
};

export default PageContent;
