import React, { useReducer, useEffect } from "react";
import { screenInitialState, screenReducer } from "../../shared/reducers/screenReducer";
import axiosInstance from "../../../config/axios";
import TextSwiper from "../discoverUs/textSwiper/TextSwiper";
import { Loading } from "../../shared/loading/Loading";
import logo from "../../../assets/images/desktopLogo.png"
import qr1 from "../../../assets/images/qr1.jpg"
import qr2 from "../../../assets/images/qr2.jpg"
import qr3 from "../../../assets/images/qr3.jpg"
import qr4 from "../../../assets/images/qr4.jpg"
import qr5 from "../../../assets/images/qr5.jpg"
import qr6 from "../../../assets/images/qr6.jpg"
import qr7 from "../../../assets/images/qr7.jpg"
import "./indexStyles.scss"
import { Col, Row } from "react-bootstrap";
const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

const Index = () => {
    const [detailsState, detailsDispatch] = useReducer(
        screenReducer,
        screenInitialState
    );
    const getYear = () => {
        return new Date().getFullYear();
    };
    const loadItem = async () => {
        detailsDispatch({
            type: "FETCH_REQUEST",
        });
        await sleep(1000);
        try {
            const res = await axiosInstance.get(`external-links`);
            detailsDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
        } catch (error) {
            console.log(error)
            detailsDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };
    useEffect(() => {
        loadItem();

        document.title = "External Links";
    }, []);
    return (
        
                <div class="container-fluid mainContainer externalLinks">

                    <nav class="appHeader py-0 uniPaddingHeader topMenu">
                    <div id="mainMenu" class="w-100 h-100">
                        <div class="row h-100 m-0 mt-3 p-0 mainRow">
                            <div class="col-md-6 col-lg-9 col-xl-9 col-xxl-9 logoContainer">
                                <a href="https://www.memoryoftime.com/" target="_blank">
                                    <img src={logo} class="appDesktopLogoSmall" alt="Memory of Time" />

                                </a>
                            </div>
                            <div class="cartColumn col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                            <a href="https://www.memoryoftime.com/" target="_blank" title="Please click to view the website">
                                <span class="goToWebsite">Go To Website</span>
                            </a>
                            </div>
                        </div>
                    </div>

                    </nav>


                    <div class="ourHistorySection">

                        <div class="row ms-xl-1">
                            <div class="col-xl-12 py-xl-5 py-lg-5 py-md-5 py-2 ">
                                <div class="row uniPadding innerSection py-xl-3 py-lg-3 py-md-3">
                                    <div class="container px-xl-0 px-lg-0 px-md-0 px-4">
                                        <div class="row boxHolder">

                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.memoryoftime.com/" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr1} alt="Earth Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Visit our Website</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.memoryoftime.com/contact-us" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr2} alt="Phone Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>For Inquiry Contact us</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.facebook.com/memoire.dutemps/" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr3} alt="Facebook Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Like our page on Facebook</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.instagram.com/memoryoftimelb/?igsh=MTB0Y3c3MGI1aTdlNw%3D%3D&utm_source=qr" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr4} alt="Instagram Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Follow us on Instagram</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.tripadvisor.com/Attraction_Review-g298264-d6948042-Reviews-Memory_of_Time-Byblos_Mount_Lebanon_Governorate.html" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr5} alt="Tripadvisor Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Review us on Tripadvisor</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.linkedin.com/company/memory-of-time" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr6} alt="Instagram Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Follow us on LinkedIn</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                            <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.youtube.com/channel/UC4Ug3FklOekB2pPe7KhQFTw" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr7} alt="Instagram Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Subscribe to our Youtube</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            {/* <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                <a href="https://www.memoryoftime.com/" target="_blank">
                                                    <div class="qr-item">
                                                        <div class="qr-icon">
                                                            <img src={qr1} alt="Earth Icon" />
                                                        </div>
                                                        <div class="qr-text">
                                                            <p>Visit our Website</p>
                                                            <div class="line"></div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div> */}
                                        {console.log(detailsState)}
                                            {/* DYNAMIC CODE */}
                                            {(detailsState && detailsState.data && detailsState.data.items && detailsState.data.items.length > 0) && detailsState.data.items.map((item, index) => {
                                                return (

                                                    <div class="col-xl-6 col-lg-6 col-md-8 mb-3">
                                                        <a href={item.link} target="_blank">
                                                            <div class="qr-item">
                                                                <div class="qr-icon">
                                                                    <img src={qr1} alt="Earth Icon" />
                                                                </div>
                                                                <div class="qr-text">
                                                                    <p>{item.title}</p>
                                                                    <div class="line"></div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>

                                                )
                                            })}



                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>





                    <footer>
                        <div class="footerSection center">
                            <div class="row w-100 footerRow uniPadding">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 ">
                                    <div class="AheadLogoWrapper">
                                        <div class="AHEADLogoContainer">
                                            <span>
                                                <a
                                                    href="https://www.ahead.pro"
                                                    target="_blank"
                                                    class="aheadLogoAnimation"
                                                ></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-12 col-sm-12 col-md-6 col-lg-6 copyRightColumn end"
                                >
                                    <p class="copyRight">
                                        &copy;{getYear()} Memory of time, All rights reserved.
                                    </p>
                                </div>
                                {/* <div
                                    class="col-12 col-sm-6 col-md-8 col-lg-4 col-xl-4 end hideOnMobileTablet"
                                >
                                    <div class="socialMedia center">

                                        <span>
                                            <a title="Visit our facebook page"
                                                href="https://www.facebook.com/memoire.dutemps/"
                                                target="_blank"
                                                class="socialItem center"
                                            >
                                                <i class="fa-brands fa-facebook-f socialIcon"></i>
                                            </a>
                                        </span>

                                        <span>
                                            <a title="Visit our youtube channel"
                                                href="https://www.youtube.com/channel/UC4Ug3FklOekB2pPe7KhQFTw"
                                                target="_blank"
                                                class="socialItem center"
                                            >
                                                <i class="fa-brands fa-youtube socialIcon"></i>
                                            </a>
                                        </span>


                                    </div>

                                    <span>
                                        <a
                                            href="http://twitter.com/intent/tweet?text=%23memoryoftime"
                                            target="_blank"
                                            class="hashtag mb-0 ps-1"
                                        >
                                            #memoryoftime
                                        </a>
                                    </span>

                                </div> */}
                            </div>
                        </div>
                    </footer>

                </div>
          
    );
};

export default Index;
